module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"thibaut-trouve","short_name":"thibaut-trouve","start_url":"/","background_color":"#252C2E","theme_color":"#252C2E","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29d1f4d1b1898e23aa9d157f5d20d6e6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-172671003-1","head":true,"anonymize":true,"exclude":[],"defer":false,"respectDNT":false,"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["fr","en"],"defaultLanguage":"fr","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
