import React, { createContext, useContext, useReducer } from "react"

import PropTypes from "prop-types"

const defaultState = {
  state: {
    navSticky: false,
  },
  setNavSticky: () => {},
}

const AppContext = createContext(defaultState)

const SET_NAV_STICKY = "SET_NAV_STICKY"

const reducer = ({ state }, action) => {
  switch (action.type) {
    case SET_NAV_STICKY:
      return {
        state: {
          ...state,
          navSticky: action.payload,
        },
      }
    default:
      return state
  }
}

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState)

  const contextProviderValue = {
    ...state,
    setNavSticky: payload =>
      dispatch({
        type: SET_NAV_STICKY,
        payload,
      }),
  }

  return (
    <AppContext.Provider value={contextProviderValue}>
      {children}
    </AppContext.Provider>
  )
}

AppProvider.defaultProps = {}

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export { AppProvider }

export default () => useContext(AppContext)
